import React from 'react';

const Stars = ({ height, width, cn }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 208 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 mb-2 md:h-10 md:mb-1 ${cn}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.606 15.3175C136.424 20.2801 134.992 18.5623 134.992 24.0975C140.289 21.7593 140.098 22.0933 144.297 23.9066C144.631 22.0456 143.963 20.8527 144.345 19.1349C144.87 16.6059 145.681 17.2262 146.635 14.7449C141.482 14.5063 143.057 14.3631 139.716 9.97314C137.33 13.5997 138.667 13.8382 132.606 15.3175Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.606 15.3174C136.424 20.28 134.992 18.5622 134.992 24.0974C137.283 23.0953 138.571 22.5704 139.621 22.475V10.1162C137.378 13.5996 138.571 13.8859 132.606 15.3174Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.836 12.8359C11.2136 32.1615 11.4999 25.147 0 37.7921C16.9396 43.4227 15.8899 43.8999 21.5205 56.7359C26.1491 53.1094 27.1512 49.0534 31.5889 45.904C38.0307 41.3232 38.6033 44.2817 45.9518 40.7029C34.7382 29.3938 38.5556 32.3523 40.1303 15.4127C27.1989 18.8483 29.6802 22.1408 12.836 12.8359Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.836 12.8359C11.2136 32.1615 11.4999 25.147 0 37.7921C7.34847 40.2257 11.309 41.7049 13.9335 43.6613L39.6531 15.5558C27.2466 18.9438 29.4416 21.9977 12.836 12.8359Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.522 44.1863C116.144 63.5118 115.858 56.4974 127.358 69.1424C110.418 74.7731 111.468 75.2503 105.837 88.0862C101.208 84.4597 100.206 80.4037 95.7687 77.2544C89.3268 72.6735 88.7542 75.632 81.4058 72.0532C92.6193 60.7442 88.8019 63.7027 87.2273 46.763C100.159 50.1987 97.6774 53.4912 114.522 44.1863Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.521 44.1863C116.144 63.5118 115.858 56.4974 127.357 69.1424C120.009 71.576 116.048 73.0553 113.424 75.0117L87.7043 46.9062C100.111 50.2941 97.8681 53.348 114.521 44.1863Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.285 0C183.092 13.8857 183.33 8.82771 175.075 17.894C187.195 21.95 186.48 22.284 190.488 31.4934C193.78 28.9167 194.496 26.0059 197.693 23.7155C202.322 20.423 202.751 22.5703 208 19.9936C199.984 11.8816 202.704 14.0289 203.849 1.86097C194.592 4.34228 196.357 6.68043 184.285 0Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.285 0C183.092 13.8857 183.33 8.82771 175.075 17.894C180.372 19.6595 183.187 20.7093 185.048 22.0931L203.467 1.90869C194.639 4.38999 196.214 6.58499 184.285 0Z"
      fill="black"
    />
  </svg>
);

export default Stars;
