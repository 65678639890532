import React from 'react';

const ArrowIcon = ({
  height = '22',
  width = '23',
  color = '#000',
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 22"
    fill="#000"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0216675 11C0.0216675 17.0751 5.05334 22 11.2602 22C17.4671 22 22.4988 17.0751 22.4988 11C22.4988 4.92487 17.4671 0 11.2602 0C5.05334 0 0.0216675 4.92487 0.0216675 11ZM20.4554 11C20.4554 15.9706 16.3386 20 11.2602 20C6.18186 20 2.06504 15.9706 2.06504 11C2.06504 6.02944 6.18186 2 11.2602 2C16.3386 2 20.4554 6.02944 20.4554 11ZM14.6245 10.2929L16.0694 11.7071L11.2602 16.4142L6.45103 11.7071L7.89592 10.2929L11.2602 13.5858L14.6245 10.2929ZM14.6245 6.29289L11.2602 9.58579L7.89592 6.29289L6.45103 7.70711L11.2602 12.4142L16.0694 7.70711L14.6245 6.29289Z"
      fill={color}
    />
  </svg>
);

export default ArrowIcon;
