import React from 'react';

const Rocket = ({ height = '147', width = '92' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 92 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7294 94.6911C26.9294 88.4911 27.6294 82.5911 28.7294 76.7911C32.3294 76.5911 34.4293 77.0911 37.5293 77.6911C40.4293 78.2911 43.2294 79.4911 45.8294 79.8911C46.0294 78.6911 45.6294 79.4911 46.8294 78.3911L50.7294 75.0911C53.2294 75.6911 56.6293 79.2911 63.4293 75.6911C68.9293 72.6911 70.7293 65.0911 66.5293 59.0911C64.2293 55.8911 67.4294 56.2911 67.3294 54.2911C66.6294 53.6911 66.2293 53.5911 65.5293 53.2911L61.0293 51.3911C58.6293 50.4911 57.5294 49.9911 54.7294 49.1911C42.0294 45.3911 48.9293 47.6911 42.6293 47.0911C41.4293 46.9911 41.6293 47.4911 40.9293 46.3911L39.4293 48.9911C35.9293 54.1911 34.9294 57.0911 32.7294 62.7911C32.2294 64.0911 31.0293 68.0911 30.4293 68.9911C27.4293 68.1911 26.2293 67.5911 22.1293 67.6911C17.1293 67.8911 13.7293 69.7911 10.6293 73.0911C5.02929 79.1911 2.52934 86.5911 0.929341 95.0911C0.129341 99.1911 -0.970707 108.891 1.62929 112.091C3.72929 111.991 4.42929 108.791 6.12929 105.891C11.1293 97.1911 15.6294 92.1911 26.7294 94.6911Z"
      fill="#AFB9C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9293 79.891C46.9293 80.691 49.8294 81.291 52.2294 82.791C57.4294 85.891 65.2293 90.391 68.3293 95.291C65.4293 99.191 56.4294 108.091 56.2294 108.391C60.1294 111.691 63.3293 114.591 62.9293 122.191C62.5293 129.191 57.8293 135.191 58.4293 138.591C64.9293 138.791 75.9294 124.091 79.2294 117.891C81.6294 113.291 83.7294 107.391 82.7294 100.891C81.9294 95.891 76.9293 90.491 73.1293 89.191C73.3293 88.691 79.0294 79.291 81.7294 72.591C82.1294 71.491 83.4293 67.091 83.9293 66.591L82.5293 65.391C81.6293 63.691 82.0293 65.691 80.5293 63.091C79.7293 61.691 79.7293 62.391 78.8293 61.591L77.7294 60.491C75.6294 59.291 77.1293 61.591 75.9293 58.691L74.6293 58.491C74.5293 58.491 74.4293 58.391 74.3293 58.391L73.6293 58.091C73.5293 57.991 73.3294 57.891 73.2294 57.791L67.3293 54.291C67.4293 56.291 64.2293 55.991 66.5293 59.091C70.7293 65.091 69.0293 72.691 63.4293 75.691C56.7293 79.291 53.2294 75.791 50.7294 75.091L46.8293 78.391C45.7293 79.491 46.1293 78.691 45.9293 79.891Z"
      fill="#AFB9C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7293 94.691C26.4293 100.891 26.7293 107.491 30.7293 109.791C36.6293 110.391 36.7293 106.991 41.4293 114.991L46.0293 115.091C47.8293 114.691 50.2293 112.991 51.5293 111.991C52.6293 111.191 55.6293 108.691 56.4293 108.291C56.6293 107.991 65.6293 99.091 68.5293 95.191C65.4293 90.291 57.5293 85.791 52.4293 82.691C49.9293 81.191 47.0292 80.591 46.1292 79.791C43.5292 79.391 40.7293 78.1911 37.8293 77.5911C34.7293 76.9911 32.5293 76.491 29.0293 76.691C27.6293 82.591 26.9293 88.591 26.7293 94.691Z"
      fill="#AFB9C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.8293 7.69094C83.0293 10.1909 76.7293 23.8909 75.5293 25.6909C66.3293 27.1909 63.4293 27.591 55.3293 32.491L52.3293 34.591C48.2293 38.091 44.0293 41.691 41.0293 46.491C41.7293 47.591 41.5294 46.9909 42.7294 47.1909C49.0294 47.8909 42.1293 45.5909 54.8293 49.2909C57.6293 50.0909 58.8293 50.591 61.1293 51.491L65.6293 53.391C66.3293 53.691 66.6293 53.791 67.4293 54.391L73.3293 57.891C73.4293 57.991 73.6294 58.0909 73.7294 58.1909L74.4293 58.491C74.5293 58.491 74.6294 58.591 74.7294 58.591L76.0293 58.7909C77.2293 61.6909 75.7293 59.391 77.8293 60.591L78.9293 61.6909C79.8293 62.4909 79.8293 61.8909 80.6293 63.1909C82.0293 65.7909 81.6293 63.791 82.6293 65.491L84.0293 66.6909C86.1293 56.9909 86.6293 50.591 84.3293 40.991C83.6293 37.891 83.0294 36.591 81.7294 33.891C80.5294 31.391 79.6293 30.091 78.3293 27.891C77.2293 26.091 78.5293 24.691 80.0293 21.491L86.1293 8.09096L84.7294 7.29095C83.9294 7.59095 84.1293 6.79094 83.8293 7.69094Z"
      fill="#AFB9C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1293 120.891C17.8293 128.591 18.1294 138.591 19.3294 146.691C24.0294 146.691 37.9294 133.591 40.2294 126.891C41.1294 126.191 40.3293 127.191 41.1293 125.891C44.8293 120.291 45.2293 110.991 36.5293 109.191C28.7293 107.591 23.6293 114.891 21.1293 120.891Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.2293 56.691C53.4293 57.491 52.1292 57.891 50.1292 60.491C44.7292 67.291 51.5292 77.891 60.0292 75.691C69.1292 73.391 69.5292 61.991 63.5292 58.291C61.7292 57.191 58.9293 55.891 56.2293 56.691Z"
      fill="#00B2FB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.8294 7.69102C84.1294 6.79102 83.9293 7.59105 84.6293 7.09105L86.0293 7.89104C91.8293 8.39104 92.5294 1.99103 88.8294 0.291029C87.3294 -0.408971 84.4293 0.191023 83.6293 1.69102C82.0293 4.59102 84.2294 6.19102 83.8294 7.69102Z"
      fill="black"
    />
  </svg>
);

export default Rocket;
