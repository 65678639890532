import React from 'react';

const Earth = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 153 306"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-20 lg:h-32"
  >
    <path
      d="M220.27 237.868C267.3 200.557 275.179 132.186 237.868 85.1555C200.557 38.1254 132.186 30.2462 85.1556 67.557C38.1254 104.868 30.2463 173.239 67.5571 220.27C104.868 267.3 173.24 275.179 220.27 237.868Z"
      fill="#B3E9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.2023 67.5455C132.202 30.2455 200.602 38.1455 237.902 85.1455C275.202 132.145 267.302 200.545 220.302 237.845C220.102 238.045 219.902 238.145 219.602 238.345L84.6023 68.1455C84.7023 67.9455 84.9023 67.7455 85.2023 67.5455Z"
      fill="#CFF1FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.602 96.5455C129.102 104.545 130.802 106.245 139.502 115.745C145.002 121.745 160.002 123.145 160.002 123.145C163.202 124.845 159.702 123.546 162.502 122.245C173.602 117.046 175.302 117.146 175.502 110.245C175.802 97.2455 169.002 108.045 177.102 97.2455L179.402 92.5455C199.602 97.3455 189.002 86.6455 208.902 97.8455C215.702 95.6455 218.202 95.5455 224.202 94.9455C225.702 90.2455 227.002 89.2455 225.502 84.0455C223.802 78.1455 220.102 77.4455 221.402 73.4455C205.202 54.5455 187.102 47.5455 166.602 45.0455C146.602 42.5455 127.702 45.7455 105.902 62.1455L109.502 66.4455C101.702 80.6455 110.102 82.5455 109.802 92.3455L112.802 93.5455C118.402 95.3455 110.002 90.7455 117.402 94.9455L115.102 101.745L123.602 105.845C125.302 103.445 125.102 104.045 126.102 101.345C126.302 100.945 126.702 99.4455 126.802 99.0455L127.602 96.5455Z"
      fill="#94E0FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.002 139.946L131.902 142.846C123.502 149.846 111.602 141.946 104.202 137.246C91.7023 129.346 93.5023 150.346 73.6023 145.946C62.3023 155.446 59.2023 155.646 60.1023 168.646C60.5023 174.246 58.5023 174.446 60.1023 180.046C61.3023 184.446 65.4023 189.546 69.0023 193.046C73.3023 193.346 79.8023 194.846 83.3023 193.046C91.0023 189.146 87.0023 184.246 98.0023 181.346C102.002 182.546 103.702 185.546 108.802 188.046C119.802 193.446 120.002 205.846 141.502 211.646C150.202 205.346 149.302 204.046 146.902 191.146L146.102 187.746C146.002 187.346 145.802 186.946 145.702 186.646C142.702 177.746 145.402 182.746 144.502 177.146C142.602 166.246 133.902 171.846 136.102 158.546C137.102 151.546 139.602 146.746 134.002 139.946Z"
      fill="#94E0FF"
    />
  </svg>
);

export default Earth;
